$coverImage: "../img/cover-blur.jpg";
$coverMinHeight: 860px;
$coverPaddingTop: 300px;
$coverTextColor: #000000;
$coverTextSize: 18pt;

.cover-image-container {
  min-height: 100%;
  min-height: 100vh;

  display:flex;
  align-items: center;
  justify-content: center;

  color: $coverTextColor;
  font-size: $coverTextSize;

  box-shadow: 0 0 5px black;
}

.cover-image {
  background-size: contain;
  background: url($coverImage) no-repeat center;
}

.title-spacing {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}