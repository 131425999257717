//noinspection CssUnknownTarget
$enable-rounded: false;
@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "homepage";
@import "gmaps";
@import "footer";

.navbar {
  box-shadow: 0 0 4px black;
}

